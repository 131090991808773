import React, { useState, useEffect } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Benefits.module.scss"

const benefits = [
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/paid_leaves_f63e497c8b.png",
    name: "Paid Leaves",
    details:
      "Make the most of your vacations, efficiently manage personal responsibilities, or simply unwind with the benefit of your annual quota of paid leaves.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/child_education_support_423072930b.png",
    name: "Child Education Support",
    details:
      "InvoZone plays a crucial part in enhancing your children's education quality, actively supporting their learning journey and overall growth.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gym_allowance_c42f98f973.png",
    name: "Gym Allowance",
    details:
      "Prioritizing your well-being is our concern. Stay committed to your fitness journey and lead in the game of life. Your energy drives your achievements.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/provident_fund_9b7016d117.png",
    name: "Provident Fund",
    details:
      "Imagine a retirement life without worries. Let's collaborate to secure your financial future. Together, we'll build wealth for lasting peace of mind.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/training_fund_49cc364332.png",
    name: "Training Fund",
    details:
      "We empower our team to pursue certifications, channelizing their journey to attain greater achievements & excellence. Your success is our motivation!",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/growing_and_dynamic_team_139a21f7b6.png",
    name: "Growing And Dynamic Team",
    details:
      "A team that's continuously growing creates a rich environment for learning, ensuring countless opportunities for personal and professional development.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/medical_coverage_92e4f97578.png",
    name: "Medical Coverage",
    details:
      "Our company offers comprehensive In-Patient/Out-Patient coverage. While essential, we hope you stay healthy, hoping this perk remains untouched! ",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/flexible_timing_ea25377fa7.png",
    name: "Flexible Timings",
    details:
      "Can't make it by 9 a.m.? No problem! Join us whenever you can and experience our enriched culture while enjoying a flexible work environment.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gaming_facility_2b1eb9cd6b.png",
    name: "Gaming Facility",
    details:
      "The more, the merrier! Don't let the office be dull. Connect with your team and bring a joyful atmosphere to work. Let's make work fun and enjoyable!",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/personal_development_fund_7f8a9d8ef1.png",
    name: "Personal Development Fund",
    details:
      "Explore a world of endless possibilities! Participate in insightful seminars, get to know about new gadgets, or create your ideal home workspace.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/annual_increament_ede25d3583.png",
    name: "Annual Increment",
    details:
      "When you appreciate us and your work, we reciprocate by valuing the time you've invested with us. It's a two-way street of respect and commitment!",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/work_from_home_6985a8b161.png",
    name: "Work From Home",
    details:
      "Own your schedule. Experience work-from-home freedom: Enjoy up to 3 days at home per month, striking the ideal balance between comfort and productivity.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/performance_based_bonus_a6a30c2cf0.png",
    name: "Performance-Based Bonuses",
    details:
      "Exceptional dedication and remarkable performance don't go unnoticed here. We appreciate our hardworking and top-performing employees with bonuses.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/advance_salary_65bd12540f.png",
    name: "Advance Salary",
    details:
      "Tight on a budget? We've got your back. Get a jumpstart on your salary when you're in a pinch, ensuring you're covered during those critical moments.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/loan_b495f89ec0.png",
    name: "Personal Loans | Home Loans | Vehicle Lease",
    details:
      "At InvoZone, we go the extra mile by offering comprehensive solutions for car & home financing programs. Secure your dream assets with our assistance.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/paid_internship_418abe5de1.png",
    name: "Paid Internships",
    details:
      "Join paid internships! Learn from experts, gain practical skills, and set the stage for a successful career. It's a future investment in your success.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/annual_trips_26e1f12f1a.png",
    name: "Annual Recreational Trips",
    details:
      "At InvoZone, we plan enjoyable annual leisure and recreational trips, giving our employees a chance to relax, unwind, bond, and make lasting memories.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/company_dinners_2fa0fa1cc6.png",
    name: "Team And Company-Wide Dinners",
    details:
      "Join the fun at InvoZone! Meet different teams, make friends, and have great company dinners. It's a chance to connect and enjoy good times together!",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/self_service_panatry_98ade7ffba.png",
    name: "Self-Service Pantry",
    details:
      "Satisfy your tea or coffee cravings at InvoZone's self-service pantry. A cozy workspace setting offering beverages and snacks for everyone to enjoy!",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/marriage_bonus_73485b0d94.png",
    name: "Marriage Bonus",
    details:
      "Here's to new beginnings! InvoZone welcomes your marital journey by offering a special allowance, making your new start even more joyful and smooth.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/daycare_facility_2500341858.png",
    name: "Daycare Facility",
    details:
      "Recognizing the importance of family, we prioritize work-life balance by allowing employees to promote family well-being and job satisfaction on-site.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/subsidized_lunch_facility_8f6166ae85.png",
    name: "Subsidized Lunch Facility ",
    details:
      "Caring for our team's health, we offer subsidized lunches for everyone. Eat well, save money, and stay happy. Join us in enjoying good food every day!",
  },
]

const PerkCard = ({ data }) => {
  return (
    <div className={styles.flipbox}>
      <div className={styles.flipboxinner}>
        <div className={styles.flipboxfront}>
          <div className={`p-4 ${styles.card}`}>
            <div>
              {" "}
              <div className="mb-2">
                <img
                  src={data.icon}
                  decoding="async"
                  loading="lazy"
                  alt={data.name}
                  height={60}
                  width={60}
                />
              </div>
              <h3>{data.name}</h3>
            </div>
          </div>
        </div>
        <div className={styles.flipboxback}>
          <div className={`p-4 ${styles.card}`}>
            <div className={styles.flipbackText}>
              <p>{data.details}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Benefits = () => {
  const [list, setList] = useState([])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const length = 8

  useEffect(() => {
    setList(benefits?.slice(0, length))
  }, [benefits])

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list?.length
      const isMore = currentLength < benefits?.length
      const nextResults = isMore
        ? benefits?.slice(currentLength, currentLength + length)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    const isMore = list.length < benefits?.length
    setHasMore(isMore)
  }, [list, benefits])

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  return (
    <section className={styles.benefits}>
      <Container>
        <div className="text-center">
          <h2 className="h1 main-heading-36">
            The Perks and Benefits We Offer
          </h2>
          <Row className="justify-content-center">
            <p className={styles.dec}>
              Culture. Teamwork. Innovation! We're here to help you take big
              steps, try new things, and create chances to grow in your career.
            </p>
          </Row>
        </div>
        <Row>
          {list.map((b, i) => (
            <Col
              xs={12}
              md={6}
              lg={4}
              xl={3}
              key={i}
              style={{ padding: 10, display: "flex" }}
            >
              <PerkCard data={b} ind={i} />
            </Col>
          ))}
        </Row>
        {hasMore && (
          <div className={`${styles.bannerBtn}`}>
            <button className={styles.applyBtn} onClick={handleLoadMore}>
              Load More Benefits
            </button>
          </div>
        )}
      </Container>
    </section>
  )
}

export default Benefits
