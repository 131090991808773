import { graphql } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Spinner from "react-bootstrap/Spinner"
import Benefits from "../components/careers-sections/Benefits"
import MainLayout from "../layouts/MainLayout"

const JobApplicationForm = React.lazy(() =>
  import("../components/common/JobApplicationForm")
)

const card = {
  background: "linear-gradient(265deg, #1ca3e9 0%, #2176ff 100%)",
  padding: "35px 25px",
  borderRadius: 25,
  color: "#fff",
}

const JobTemplate = ({ data }) => {
  const { title, description, applyBefore, totalPosition, cities, country } =
    data?.strapiJob
  const isSSR = typeof window === "undefined"

  return (
    <MainLayout bgChanged={false}>
      <section className="pb-0">
        <Container>
          <Row className={`px-2 px-md-3`}>
            <Col xs={12} md={6} lg={7} className="pl-0">
              <Row>
                <Col xs={12}>
                  <h1 className="main-banner-heading-55">{title}</h1>
                </Col>
                <Col xs={12} className="pb-3 font-weight-bold">
                  <div>
                    Apply before {applyBefore} | Total Positions :{" "}
                    {totalPosition}
                  </div>
                </Col>
                <Col xs={12}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: description?.description,
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} lg={5}>
              <Row
                className="justify-content-center"
                style={{ position: "sticky", top: "80px" }}
              >
                <Col md={12} lg={10} className="px-0">
                  <div style={card}>
                    <h4 className="mb-4 text-center">
                      Submit Your Application
                    </h4>
                    {!isSSR && (
                      <React.Suspense
                        fallback={
                          <div className="text-center">
                            <Spinner
                              variant="primary"
                              size="sm"
                              as="span"
                              animation="border"
                              role="status"
                              aria-hidden="true"
                            />
                          </div>
                        }
                      >
                        <JobApplicationForm
                          jobTitle={title}
                          cities={cities?.strapi_json_value}
                          country={country}
                        />
                      </React.Suspense>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <Benefits />
    </MainLayout>
  )
}

export const query = graphql`
  query getJob($id: String) {
    strapiJob(id: { eq: $id }) {
      title
      totalPosition
      applyBefore(formatString: "MMMM DD, YYYY")
      description: childStrapiJobDescriptionTextnode {
        description
      }
      country
      cities {
        strapi_json_value
      }
    }
  }
`

export default JobTemplate

export const Head = ({ data }) => {
  const { title } = data?.strapiJob

  return (
    <>
      <title>{title}</title>
      <meta
        name="description"
        content={`Your Ultimate Technology Partner. Explore ${title}. Join Our Community.`}
      />
      <meta name="robots" content="noindex, nofollow"></meta>
      <meta name="googlebot" content="noindex, nofollow"></meta>
    </>
  )
}
