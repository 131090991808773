// extracted by mini-css-extract-plugin
export var applyBtn = "Benefits-module--applyBtn--6fc7c";
export var backHead = "Benefits-module--backHead--af096";
export var bannerBtn = "Benefits-module--bannerBtn--35e22";
export var benefits = "Benefits-module--benefits--45c05";
export var card = "Benefits-module--card--2ac53";
export var dec = "Benefits-module--dec--dfa26";
export var flipbackText = "Benefits-module--flipbackText--fd208";
export var flipbox = "Benefits-module--flipbox--68471";
export var flipboxback = "Benefits-module--flipboxback--703ef";
export var flipboxfront = "Benefits-module--flipboxfront--13390";
export var flipboxinner = "Benefits-module--flipboxinner--ece7b";
export var hr = "Benefits-module--hr--1d2b5";